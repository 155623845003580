<template>
  <div>
    <v-row
      v-if="isAnimation"
      class="ma-0 pa-0"
    >
      <v-col
        :cols="dailyData ? 12 : 6"
        class="pb-0 pl-0"
      >
        <v-menu
          ref="forecastRangeMenu"
          v-model="forecastRangeMenu"
          :close-on-content-click="false"
          :return-value.sync="forecastStartDate"
          :attach="`.forecastRangeMenu${serviceCardId}`"
          transition="scale-transition"
          offset-y
          max-width="300px"
          min-width="300px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="forecastStartDate"
              chips
              small-chips
              :label="$t('ServiceCard.date')"
              :class="`forecastRangeMenu${serviceCardId}`"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              clearable
              v-on="on"
              @click:clear="forecastStartTime = ''"
              @click="changeMinMaxDates()"
            />
          </template>
          <v-date-picker
            v-model="forecastStartDate"
            no-title
            scrollable
            full-width
            :min="minDate"
            :max="maxDate"
            :locale="app_user.app_user_data[0].language"
            first-day-of-week="1"
            @input="$refs.forecastRangeMenu.save(forecastStartDate);"
          />
        </v-menu>
      </v-col>
      <v-col
        v-if="!dailyData"
        cols="6"
        class="pb-0"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="forecastStartTime"
              :label="$t('ServiceCard.hourly')"
              prepend-icon="mdi-clock-time-four-outline"
              clear-icon="mdi-calendar"
              value="00:00:00"
              type="time"
              max-width="290px"
              min-width="290px"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span>{{ $t('ServiceCard.localTime') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <div
      v-if="isAnimation"
      class="d-flex justify-center"
    >
      <v-btn
        id="submit"
        color="#0da344"
        small
        style="color:white"
        class="mb-2"
        :disabled="disableConfirmButton()"
        @click="changeDate()"
      >
        {{ $t('ServiceCard.confirmFilter') }}
      </v-btn>
    </div>
    <v-alert
      :value="alert"
      dense
      type="warning"
      icon="mdi-alert-outline"
      class="mt-4"
      transition="scale-transition"
      dismissible
    >
      Unauthorized time interval, please try again.
    </v-alert>
    <animation-player
      v-if="!alert"
      :selected-raster-layer="selectedRasterLayer"
      :selected-vector-layer="selectedVectorLayer"
      :service-card-id="serviceCardId"
      :animation-limit-range-array="animationLimitRangeArray"
      :animation-limit-range-array-policy="animationLimitRangeArrayPolicy"
      :animation-step-date-type="animationStepDateType"
      :animation-step="animationStep"
      :min-step="minStep"
      :max-step="maxStep"
      :is-animation="isAnimation"
      :overlay="overlay"
      :service-name="serviceName"
      :layer-type="layerType"
      :animation-time-array="animationTimeArray"
      :animation-empty-steps="animationEmptySteps"
      :animation-start-index="animationStartIndex"
      :no-features-found="noFeaturesFound"
      :no-wmts-features-found="noWmtsFeaturesFound"
      @no-raster-features-found="$emit('no-raster-features-found', $event)"
      @no-vector-features-found="$emit('no-vector-features-found', $event)"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { RASTER_BASE_URL } from '@/constants.js';
import { getFormattedCurrentDate } from '@/utils.js';

export default {
  components: {
    AnimationPlayer: () => import('@/components/utils/AnimationPlayer')
  },
  props: {
    serviceCardId: {
      type: String,
      default: ''
    },
    animationStepDateType: {
      type: String,
      default: ''
    },
    animationStep: {
      type: String,
      default: ''
    },
    layerType: {
      type: String,
      default: ''
    },
    serviceName: {
      type: String,
      default: ''
    },
    isAnimation: {
      type: Boolean,
      default: false
    },
    overlay: {
      type: Boolean,
      default: false
    },
    minStep: {
      type: Date,
      default: null
    },
    maxStep: {
      type: Date,
      default: null
    },
    selectedRasterLayer: {
      type: Object,
      default: null
    },
    selectedVectorLayer: {
      type: Object,
      default: null
    },
    animationLimitRangeArray: {
      type: Array,
      default: () => []
    },
    animationLimitRangeArrayPolicy: {
      type: Array,
      default: () => []
    },
    animationTimeArray: {
      type: Array,
      default: () => []
    },
    animationEmptySteps: {
      type: Array,
      default: () => []
    },
    animationStartIndex: {
      type: Number,
      default: 0
    },
    noFeaturesFound: {
      type: Boolean,
      default: false
    },
    noWmtsFeaturesFound: {
      type: Boolean,
      default: false
    },
    tableChartRangeDate: {
      type: String,
      default: ''
    },
    checkPolicyLimits: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      forecastStartTime: '00:00',
      forecastStartDate: '',
      minDate: '',
      maxDate: '',
      forecastRangeMenu: false,
      alert: false,
      isManualDate: false,
      renderAnimation: true
    };
  },
  computed: {
    ...mapState(['map', 'app_user']),
    dailyData() {
      return this.animationStepDateType === 'dynamic_months' || this.animationStepDateType === 'dynamic_days' || (this.animationStepDateType === 'dynamic_hours' && this.animationStep >= 24);
    },
    dailyVector() {
      return this.dailyData && this.layerType === 'VECTOR';
    }
  },
  watch: {
    $route() {},
    animationTimeArray: {
      async handler(newValue) {
        if (newValue && this.animationTimeArray.length > 0 && this.isManualDate) {
          this.openSelectedLayer();
          this.isManualDate = false;
        }
      }
    }
  },
  created() {
    if (!this.overlay) {
      const textfieldDate = this.layerType === 'RASTER' ? this.animationTimeArray[this.animationStartIndex] : this.animationTimeArray[this.animationStartIndex].split('/')[0];
      const [date, hour] = getFormattedCurrentDate(new Date(textfieldDate)).split(' ');

      this.forecastStartDate = date;
      this.forecastStartTime = hour;
    }
  },
  methods: {
    ...mapActions(['setRasterLayerAnimationTime', 'removeLayer', 'loadVectorLayer', 'loadRasterLayer', 'resetAnimationTime', 'removeLayerByServiceID', 'getLayerExtent']),
    changeMinMaxDates() {
      this.minDate = getFormattedCurrentDate(this.minStep);
      this.maxDate = getFormattedCurrentDate(this.dailyVector ? new Date(this.checkPolicyLimits.futureRangeTime - 1) : this.maxStep);
    },
    disableConfirmButton() {
      if (this.dailyData) {
        if (this.layerType === 'RASTER') {
          return !((this.forecastStartDate !== '' && Object.keys(this.selectedRasterLayer).length !== 0));
        } else {
          return !((this.forecastStartDate !== '' && Object.keys(this.selectedVectorLayer).length > 0));
        }
      } else {
        if (this.layerType === 'RASTER') {
          return !((this.forecastStartDate !== '' && this.forecastStartTime !== '' && Object.keys(this.selectedRasterLayer).length !== 0));
        } else {
          return !((this.forecastStartDate !== '' && this.forecastStartTime !== '' && Object.keys(this.selectedVectorLayer).length > 0));
        }
      }
    },
    async changeDate() {
      this.$emit('reset-min-max-step');
      const userTime = `${this.forecastStartDate} ${this.forecastStartTime}`;
      this.isManualDate = true;

      if (this.layerType === 'VECTOR') {
        await this.removeLayerByServiceID(this.serviceCardId);
      }

      if (this.checkPolicyLimits.pastRangeTime <= new Date(userTime).getTime() && this.checkPolicyLimits.futureRangeTime >= new Date(userTime).getTime()) {
        this.alert = false;
        this.$emit('set-animation-steps', [new Date(userTime)]);
      } else {
        this.alert = true;
      }
    },
    async openSelectedLayer() {
      if (this.layerType === 'RASTER') {
        const extent = await this.getLayerExtent([this.selectedRasterLayer.ws_name, this.selectedRasterLayer.layer_name, this.selectedRasterLayer.layer_type, this.selectedRasterLayer.service_id]);

        if (this.map.OLmap.getLayers().getArray().some(layer => layer.values_.service_id === this.serviceCardId)) {
          const data = {
            updatedDate: this.animationTimeArray[this.animationStartIndex],
            service_id: this.serviceCardId,
            layerType: this.layerType,
            extent: extent
          };
          this.map.rasterMarkerDate = data.updatedDate;
          await this.setRasterLayerAnimationTime(data);
        } else {
          const rasterParams = {
            url: `${RASTER_BASE_URL}/fe_ogc/geoserver/wms`,
            params: {
              ENV: '',
              GROUP: this.$route.params.id,
              LAYERS: `${this.selectedRasterLayer.ws_name}:${this.selectedRasterLayer.layer_name}`,
              TIME: this.animationTimeArray[this.animationStartIndex],
              STYLES: this.selectedRasterLayer.layer_style,
              USERNAME: this.app_user.username,
              SERVICE_NAME: this.serviceName,
              FORMAT: 'image/vnd.jpeg-png8'
            },
            TYPE: 'RASTER',
            LAYER_SERVICE_ID: this.serviceCardId,
            EXTENT: extent
          };
          this.map.rasterMarkerDate = rasterParams.params.TIME;
          await this.loadRasterLayer(rasterParams);
        }
      } else {
        await this.openVectorLayer();
      }

      this.renderAnimation = false;
      this.$nextTick(() => {
        this.renderAnimation = true;
      });
      this.resetAnimationTime();
      clearInterval(this.map.refreshIntervalID[this.serviceCardId]);
      this.$emit('set-refresh-interval');
    },
    async openVectorLayer() {
      await this.removeLayer(`${this.selectedVectorLayer.ws_name}:${this.selectedVectorLayer.layer_name}`);

      this.map.sliderOpacityValue = 60;

      const extent = await this.getLayerExtent([this.selectedVectorLayer.ws_name, this.selectedVectorLayer.layer_name, this.selectedVectorLayer.layer_type, this.selectedVectorLayer.service_id]);
      const vectorParams = {
        params: {
          GROUP: this.$route.params.id,
          LAYERS: `${this.selectedVectorLayer.ws_name}:${this.selectedVectorLayer.layer_name}`,
          TIME: this.animationTimeArray[this.animationStartIndex],
          STYLES: this.selectedVectorLayer.layer_style,
          USERNAME: this.app_user.username,
          SERVICE_NAME: this.serviceName,
          PARAM_RANGE: this.map.vectorParamRange[this.serviceCardId]
        },
        TYPE: 'VECTOR',
        LAYER_SERVICE_ID: this.serviceCardId,
        EXTENT: extent
      };
      await this.loadVectorLayer(vectorParams);
    }
  }
};
</script>

<style scoped>
.v-text-field >>> label {
  font-size: 0.8em;
}
.RMprimary-color {
  color: #00A644;
}
</style>
